import nogiLogo from "../../images/nogiManyLOGO.jpg";
import nogiPhoto from "../../images/nogiPHOTO.jpg";

import gogovid from "../../videos/IMG_2285.mp4";
import introvid from "../../videos/INTRO.mp4";
import showprogvid from "../../videos/PROMOALL.mp4";
import siyaivid from "../../videos/SIYAI.mp4";
import Welcome from "../../videos/WELCOME.mp4";
import supportvid from "../../videos/support.mp4";

import carous1 from '../../images/Performance/IMG_4992.jpg';
import carous2 from '../../images/Performance/IMG_4993.jpg';
import carous3 from "../../images/Performance/IMG_4994.jpg";
import carous4 from "../../images/Performance/IMG_4995.jpg";
import carous5 from "../../images/Performance/IMG_4996.jpg";
import carous6 from "../../images/Performance/IMG_8046.JPG";
import carous7 from "../../images/Performance/IMG_8047.JPG";
import carous8 from "../../images/Performance/IMG_8049.JPG";
import carous9 from "../../images/Performance/IMG_8050.JPG";


import artisti1 from "../../images/Sartistami/IMG_3676.jpg";
import artisti2 from "../../images/Sartistami/IMG_3677.JPG";
import artisti3 from "../../images/Sartistami/IMG_3678.JPG";
import artisti4 from "../../images/Sartistami/IMG_3679.JPG";
import artisti5 from "../../images/Sartistami/IMG_3680.JPG";
import artisti6 from "../../images/Sartistami/IMG_3681.JPG";
import artisti7 from "../../images/Sartistami/IMG_3682.JPG";
import artisti8 from "../../images/Sartistami/IMG_3683.JPG";
import artisti13 from "../../images/Sartistami/IMG_8884.JPG";
import artisti14 from "../../images/Sartistami/IMG_8895.JPG";
import artisti16 from "../../images/Sartistami/IMG_8993.JPEG";
import artisti17 from "../../images/Sartistami/IMG_8994.JPEG";
import artisti15 from "../../images/Sartistami/IMG_8995.JPEG";
import artisti20 from "../../images/Sartistami/IMG_8997.PNG";
import artisti21 from "../../images/Sartistami/IMG_8998.PNG";
import artisti22 from "../../images/Sartistami/IMG_8999.PNG";
import artisti18 from "../../images/Sartistami/IMG_9001.PNG";
import artisti19 from "../../images/Sartistami/IMG_9002.PNG";
import artisti9 from "../../images/Sartistami/IMG_9519.JPG";
import artisti10 from "../../images/Sartistami/IMG_9543.jpg";
import artisti11 from "../../images/Sartistami/IMG_9545.jpg";
import artisti12 from "../../images/Sartistami/IMG_9546.jpg";


import gogo1 from "../../images/GO-GO/IMG_2339.jpeg";
import gogo2 from "../../images/GO-GO/IMG_2340.jpeg";
import gogo3 from "../../images/GO-GO/IMG_2342.jpg";
import gogo4 from "../../images/GO-GO/IMG_2345.jpeg";
import gogo5 from "../../images/GO-GO/IMG_2356.jpg";
import gogo6 from "../../images/GO-GO/IMG_2360.jpeg";
import gogo7 from "../../images/GO-GO/IMG_2375.jpeg";
import gogo8 from "../../images/GO-GO/IMG_2412.JPG";
import gogo9 from "../../images/GO-GO/IMG_2413.jpeg";

import welcome1 from "../../images/Welcome/C1C968B5-6F74-48BF-86FF-0DA5513539BD.jpg";
import welcome2 from "../../images/Welcome/IMG_3667.JPEG";
import welcome3 from "../../images/Welcome/IMG_3668.JPEG";
import welcome4 from "../../images/Welcome/IMG_3669.jpg";

import vistup22 from "../../images/svistup/IMG_3671.jpeg";
import vistup23 from "../../images/svistup/vistup23.jpg";
import vistup24 from "../../images/svistup/vistup24.jpg";
import vistup25 from "../../images/svistup/vistup25.jpg";
import vistup26 from "../../images/svistup/vistup26.JPG";
import vistup27 from "../../images/svistup/vistup27.JPG";
import vistup28 from "../../images/svistup/vistup28.JPG";
import vistup29 from "../../images/svistup/vistup29.JPG";
import vistup30 from "../../images/svistup/vistup30.JPG";
import vistup31 from "../../images/svistup/vistup31.JPG";
import vistup32 from "../../images/svistup/vistup32.JPG";
import vistup33 from "../../images/svistup/vistup33.jpeg";
import vistup34 from "../../images/svistup/vistup34.jpeg";

import group2 from "../../images/group/IMG_0522.jpg";
import group3 from "../../images/group/IMG_8483.JPG";
import group1 from "../../images/group/photo1700070261.jpeg";

import proch1 from '../../images/prochee/IMG_3690.JPG';
import proch2 from "../../images/prochee/IMG_3691.jpg";
import proch3 from "../../images/prochee/IMG_3692.jpg";
import proch4 from "../../images/prochee/IMG_3693.jpg";
import proch5 from "../../images/prochee/IMG_3698.JPG";
import proch6 from "../../images/prochee/IMG_3699.JPG";
import proch7 from "../../images/prochee/IMG_3700.JPG";
import proch8 from "../../images/prochee/IMG_3701.JPG";
import proch9 from "../../images/prochee/IMG_3702.JPG";
import proch10 from "../../images/prochee/IMG_3703.jpg";
export const CategoriesData = [
  {
    id: 1,
    name: "Шоу-программа",
    image: { nogiLogo },
    carousel: [

      vistup22,
      vistup23,
      vistup24,
      vistup25,
      vistup26,
      vistup27,
      vistup28,
      vistup29,
      vistup30,
      vistup31,
      vistup32,
      vistup33,
      vistup34,
    ],
    subTitle: 'NOGI | ПРО СТИЛЬ И СОВРЕМЕННОЕ ВИДЕНИЕ ШОУ-БАЛЕТА',
    description:
      "В НАШЕМ РЕПЕРТУАРЕ БОЛЬШАЯ ЧАСТЬ ПОСТАНОВОК - СОВРЕМЕННЫЕ, СТИЛЬНЫЕ ОБРАЗЫ И ПОСТАНОВКИ ОТ ЛУЧШИХ ХОРЕОГРАФОВ СТРАНЫ, НО ТАКЖЕ ЕСТЬ И ЭСТРАДНЫЙ БЛОК ДЛЯ ТЕХ, КТО НЕ ГОТОВ ПЕРЕХОДИТЬ К НОВОМУ \n \nNOGI ТАНЦУЮТ: \n• СОВРЕМЕННУЮ ХОРЕОГРАФИЮ для особо искушенного зрителя (шоу в различных танцевальных стилях - High Heels, Vogue и другие) \n• ШОУ БОЛЕЕ ЗНАКОМЫЕ, НО НЕ МЕНЕЕ ЭФФЕКТНЫЕ (бразильский карнавал, кан-кан, русский народный)",
    video: { showprogvid },
  },
  {
    id: 8,
    name: "INTRO SHOW",
    image: { nogiLogo },
    carousel: [],
    subTitle: 'УНИКАЛЬНОЕ ТАНЦЕВАЛЬНО-СВЕТОВОЕ ШОУ BY NOGI & U-FLOW \n',
    description:
      "ПЕРВОЕ ВПЕЧАТЛЕНИЕ ВСЕГДА ЗАДАЕТ НАСТРОЕНИЕ, ПОЭТОМУ ТАК ВАЖНО, ЧТОБЫ ОТКРЫТИЕ МЕРОПРИЯТИЯ БЫЛО ЯРКИМ И ЗАПОМИНАЮЩИМСЯ, ВЫЗЫВАЛО ЭМОЦИЮ ЗРИТЕЛЯ МЫ СОЗДАЛИ ТО, ЧТО УДОВЛЕТВОРЯЕТ ВСЕ ЭТИ КРИТЕРИИ \n СОЧЕТАНИЕ ОБРАЗОВ, МУЗЫКИ, СВЕТА, ДВИЖЕНИЙ ТОЧНО НЕ ОСТАВИТ ВАС РАВНОДУШНЫМИ \n P.S. УВАЖАЕМЫЕ ОРГАНИЗАТОРЫ И ЗАКАЗЧИКИ, ВЫ ЦЕЛИКОМ И ПОЛНОСТЬЮ СНИМАЕТЕ С СЕБЯ ЗАДАЧУ ПО ЭФФЕКТНОМУ ОТКРЫТИЮ ВАШЕГО МЕРОПРИЯТИЯ. ОСТАЕТСЯ ЛИШЬ НАБЛЮДАТЬ ЗА ПРОИСХОДЯЩИМ И НАСЛАЖДАТЬСЯ \n \n • длительность шоу 5,5 минут \n• количество артистов от 4 до 7 \n• в номере используется профессиональное световое оборудование \n • вывод логотипа компании на световое оборудование",
    video: { introvid },
  },
  {
    id: 9,
    name: "ДИАНА КОХ & NOGI",
    image: { nogiPhoto },
    carousel: [],
    subTitle: 'ВОКАЛЬНО-ТАНЦЕВАЛЬНЫЙ ПРОЕКТ \n ТРИ СОВЕРШЕННО РАЗНЫХ ПО СТИЛЮ, НО ОДИНАКОВО АТМОСФЕРНЫХ ШОУ: \n',
    description:
      "• СИЯЙ \n Популярные русскоязычные треки известных артистов - ZIVERT, ANNA ASTI, МОЯ МИШЕЛЬ и др., а также авторский одноименный трек Дианы «Сияй» \n  Неповторимые блестящие образы, стильная подача и современная хореография станут дополнением и оставят яркое послевкусие \n \n • DIVA \n Самые известные и зажигательные треки зарубежных поп-див - BEYONCÉ, J LO, RIHANNA, MARUV \n  Окунитесь в атмосферу шика, страз, блеска, словно вы попали на концерт этих мировых звезд \n \n • DISCO \n Шоу для тех, кто точно знает такие треки как «ТОПОЛИНЫЙ ПУХ» и «МУЗЫКА НАС СВЯЗАЛА» А мы уверены, что знает эти треки абсолютно каждый \n Ожившие образы 80х и 90х годов - неон, джинса и зажигательные танцы в сопровождении потрясающего вокала от ДИАНЫ КОХ",
    video: { siyaivid },
  },
  {
    id: 2,
    name: "Go-Go",
    image: { nogiPhoto },
    carousel: [gogo1, gogo2, gogo3, gogo4, gogo5, gogo6, gogo7, gogo8, gogo9],
    subTitle: 'ТАНЦЕВАЛЬНОЕ СОПРОВОЖДЕНИЕ ВАШЕГО МЕРОПРИЯТИЯ НА AFTERPARTY ',
    description:
      "С НАМИ ТАНЦУЮТ ДАЖЕ САМЫЕ СКРОМНЫЕ ГОСТИ \n \n СОПРОВОЖДАЛИ КРУПНЕЙШИЕ МУЗЫКАЛЬНЫЕ ФЕСТИВАЛИ:\n• TRANCEMISSION | SOUNDGARDEN \nКрупнеший фестиваль электронной музыки в России \n• ПИРАТСКАЯ СТАНЦИЯ | ATLANTIS \nЛегендарный DRUM&BASS спектакль \n \nРаботали на одной площадке с известными диджеями: \nDJ FEEL, Alexander Popov, Roman Messer, Ruslan Radriges, Eximinds Somnia, Tycoos, PAPAKLEY",
    video: { gogovid },
  },
  {
    id: 3,
    name: "ART PERFORMANCE",
    carousel: [
      carous1,
      carous2,
      carous3,
      carous4,
      carous5,
      carous6,
      carous7,
      carous8,
      carous9,
    ],
    image: { nogiLogo },
    subTitle: 'СОЗДАДИМ ОБРАЗ С НУЛЯ ПОД КЛЮЧ ПО ВАШЕМУ ТЗ \n',
    description:
      "ЕСЛИ В НАШЕМ РЕПЕРТУАРЕ НЕТ НОМЕРА ИЛИ ОБРАЗА ПОД ТЕМАТИКУ ВАШЕГО МЕРОПРИЯТИЯ, МЫ СОЗДАДИМ ЕГО ВМЕСТЕ. \n \nОт вас - reference, все остальное - за нами",
    video: '',
  },
  {
    id: 4,
    name: "Welcome",
    image: { nogiPhoto },
    carousel: [
      welcome1,
      welcome2,
      welcome3,
      welcome4,
    ],
    subTitle: 'СОЗДАДИМ WOW-ЭФФЕКТ УЖЕ В МОМЕНТ СБОРА ГОСТЕЙ НА МЕРОПРИЯТИЕ ',
    description:
      " Фотозона при входе, ожившие образы под вашу тематику, а также сервис-группа (помощь при регистрации гостей, официальные награждения)",
    video: { Welcome },
  },
  {
    id: 5,
    name: "Работа с артистами",
    image: { nogiLogo },
    carousel: [
      artisti1,
      artisti2,
      artisti3,
      artisti4,
      artisti5,
      artisti6,
      artisti7,
      artisti8,
      artisti9,
      artisti10,
      artisti11,
      artisti12,
      artisti13,
      artisti14,
      artisti15,
      artisti16,
      artisti17,
      artisti18,
      artisti19,
      artisti20,
      artisti21,
      artisti22,
    ],
    subTitle: 'КОЛЛАБОРАЦИИ С ДЕЙСТВУЮЩИМИ АРТИСТАМИ, ПОДТАНЦОВКИ ДЛЯ ВОКАЛИСТОВ \n',
    description:
      "Хочешь, чтобы твое выступление заиграло новыми красками и эмоциями - оставляй заявку - мы всегда открыты к новым сотрудничествам и проектам \n\n ДЕЙСТВУЮЩИЕ СОВМЕСТНЫЕ ПРОЕКТЫ:\n • ВОКАЛЬНО-ТАНЦЕВАЛЬНЫЙ ПРОЕКТ BY ДИАНА КОХ & NOGI\n• ТАНЦЕВАЛЬНО-СВЕТОВОЕ ШОУ BY U-FLOW & NOGI",
    video: {},
  },
  {
    id: 6,
    name: "Группа поддержки",
    image: { nogiLogo },
    carousel: [group1, group2, group3],
    subTitle: '',
    description:
      "Выступления на большой состав участниц до 15 человек. Использование спортивной атрибутики - пипидастры (махнушки), флаги, боксерские перчатки и многое другое.\nСопровождали крупные спортивные мероприятия в Екатеринбурге:\n- БОКСЗАБЕГ 2023\n- КОРПОРАТИВНЫЕ ИГРЫ УРФО 2021, 2022 \n- выступления на баскетбольных матчах",
    video: { supportvid },
  },
  {
    id: 7,
    name: "Прочее",
    image: { nogiPhoto },
    carousel: [
      proch1,
      proch2,
      proch3,
      proch4,
      proch5,
      proch6,
      proch7,
      proch8,
      proch9,
      proch10,
    ],
    subTitle: 'Аренда WELCOME-зоны ',
    description:
      "Первые в Екатеринбурге представили фотозону в виде огромной стены, из которой Вашим гостям будет предложен приветственный бокал игристого. Также возможно оживить стену дополнительно - настоящие красивые ноги в ярких сапогах или любой другой обуви.",
    video: { Welcome },
  },

];
