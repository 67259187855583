import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Application from "../components/Application/Application";
import Carousel from "../components/Carousel/Carousel";
import { CategoriesData } from "../components/Categories/CategoriesData";
import Contacts from "../components/Contacts/Contacts";
import Title from "../components/UI/Title/Title";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import "../styles/App.css";
import Button from "../components/UI/Button/Button";
import divavid from "../videos/DIVA.mp4"
import siyaivid from "../videos/SIYAI.mp4"
import discovid from "../videos/DISCO.mp4"
import { ReactPhotoCollage } from "react-photo-collage";

import carous1 from '../images/Performance/IMG_4992.jpg'
import carous2 from '../images/Performance/IMG_4993.jpg'
import carous3 from "../images/Performance/IMG_4994.jpg";
import carous4 from "../images/Performance/IMG_4995.jpg";
import carous5 from "../images/Performance/IMG_4996.jpg";
import carous6 from "../images/Performance/IMG_8046.JPG";
import carous7 from "../images/Performance/IMG_8047.JPG";
import carous8 from "../images/Performance/IMG_8049.JPG";
import carous9 from "../images/Performance/IMG_8050.JPG";


const CategoriesPage = () => {
  const params = useParams();
  const router = useNavigate();

  const info = CategoriesData.find((el) => el.name === params.name);

  const setting = {
    width: '100%',
    height: ['500px', '170px'],
    layout: [3, 4],
    photos: [
      { source: carous1 },
      { source: carous2 },
      { source: carous3 },
      { source: carous4 },
      { source: carous5 },
      { source: carous6 },
      { source: carous7 },
      { source: carous8 },
      { source: carous9 }
    ],
    showNumOfRemainingPhotos: true
  };

  return (
    <div>
      <Title style={{ margin: "10px auto" }}>{info.name}</Title>
      <div className="categoriesSubTitle">{info.subTitle}</div>
      <div className="categoriesText">{info.description}</div>
      {info.name === "Шоу-программа" ? (
        <div className="butCateg">
          <Button onClick={() => router(`/dances`)}>Все номера</Button>
        </div>
      ) : (
        <></>
      )}
      {(info.name === "ДИАНА КОХ & NOGI") ? (
        <div className="player">
          <VideoPlayer video={divavid} />
          <VideoPlayer video={siyaivid} />
          <VideoPlayer video={discovid} />
        </div>
      ) : (Object.values(info.video).length > 0) ? (
        <div className="player">
          <VideoPlayer video={Object.values(info.video)[0]} />
        </div>
      ) : (
        <></>
      )}
      {(info.name === "ДИАНА КОХ & NOGI" || info.name === "INTRO SHOW") ?
        (
          <></>
        ) : (info.name === "ART PERFORMANCE") ? (
          <>
            <Title>Фото c мероприятий</Title>
            <ReactPhotoCollage {...setting} />
          </>
        ) : (
          <>
            <Title>Фото c мероприятий</Title>
            <div className="carousel">
              <Carousel carous={info.carousel} />
            </div>
          </>
        )}


      <Title>Оставьте заявку</Title>
      <Application />
      <Contacts />
    </div>
  );

};

export default CategoriesPage;
