import React, { useState } from "react";
import s from "./Slide.module.css";
const Slide = (props) => {
  const onClickHandler = () =>{
    props.setSelectImage(props.image)
    props.setOpen(true)
  }
  return (
    <div className={s.image}>
      <img src={props.image} alt="" onClick={onClickHandler} />
    </div>
  );
};

export default Slide;
