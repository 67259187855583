import React from 'react'
import s from './Description.module.css'
const Description = (props) => {
  return (
    <div className={s.mainText}>
      NOGI | DANCE PROJECT <br />
      
ПРОФЕССИОНАЛЬНЫЕ ТАНЦОРЫ, МНОГОЛЕТНИЙ ОПЫТ ВЫСТУПЛЕНИЙ НА РОССИЙСКИХ И МЕЖДУНАРОДНЫХ ПЛОЩАДКАХ, СОВРЕМЕННЫЕ КОСТЮМЫ И СТИЛЬНАЯ ХОРЕОГРАФИЯ
    </div>
  );
}

export default Description